
import { Vue } from "vue-class-component";
import fly from "flyio";
import { ActiveLoader } from "node_modules/vue-loading-overlay/types/loading";
import { setTimeout } from "timers";

export default class Launch extends Vue {
  private username = "";
  private password = "";
  private wrapperClass = "";
  private formClass = "";

  mounted(): void {
    this.username = "";
    this.password = "";
    this.wrapperClass = "";
    this.formClass = "";
  }
  onSubmit(): void {
    if (!this.username || !this.password) return;
    this.apiLaunch(this.username, this.password);
  }

  private apiLaunch(username:string, password:string): void {
    let loader = this.$loading.show();
    let url = (process.env["ApiUrls"] as any)["loginApi"] + "/api/v1/game/launch";
    fly.post(url, {
        "access_token": "7b94dae7a2bbf2fdb9f3a371c09951ab",
        "secret_key": "b638fbdf91f2e6df334f722464abc983",
        "account": username,
        "password": password,
        "game_code": "0",
        "lang": "en"
    })
    .then((response) => {
      console.log("Api launch response : ", response);
      if(response.data.code === "0" ){
        let { rawurl, url } = response.data.result;
        let gameUrl = String(rawurl);
        console.log("Api launch gameUrl : ", gameUrl);
        this.onSucess(gameUrl, loader);
      } else {
        let message = String(response.data.message);
        console.log("Api launch message : ", message);
        window.alert(message);
        this.onFail(message, loader)
      }
    })
    .catch((error) =>{
      console.warn("Api launch error : ", error);
    });
  }

  private onSucess(result:string, loader:ActiveLoader): void {
    setTimeout(() => {
      this.wrapperClass = "form-success";
      this.formClass = "fade-out";
      if (loader) loader.hide();
      setTimeout(() => location.replace(result), 500)
    }, 1000);
  }

  private onFail(message:string, loader:ActiveLoader): void {
    setTimeout(() => {
      this.username = "";
      this.password = "";
      if (loader) loader.hide();
    }, 500);
  }
}
